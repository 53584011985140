import React from 'react';
import Helmet from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';
import {
  chakra,
  ChakraProvider,
  ColorModeScript,
  theme,
} from '@chakra-ui/react';

const Grid = chakra('div', {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    gridGap: '4rem',
    h2: {
      fontSize: '1.7rem',
    },
  },
});

const DocsMenu = chakra('div', {
  baseStyle: {
    background: 'lightblue',
    padding: '2rem',
  },
});

const DocsBody = chakra('div', {
  baseStyle: {
    marginTop: '3rem',
    maxWidth: '800px',
  },
});

const DocsLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DocsMenuQuery {
      allComponentMetadata {
        edges {
          node {
            id
            displayName
          }
        }
      }
    }
  `);
  return (
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeScript initialColorMode="light" />
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Grid>
          <DocsMenu>
            <h2>SpotCast Website Docs</h2>
            <hr />
            <ul>
              <li>
                <Link to="/docs/">Overview</Link>
              </li>
              <li>
                <Link to="/docs/content">Content Management</Link>
              </li>

              <li>
                <Link to="/docs/developers">Developers</Link>
              </li>
            </ul>
            <hr />
            <h4>Components</h4>
            <ul>
              <li>
                <Link to="/docs/components">What is a component?</Link>
              </li>
              {data.allComponentMetadata.edges.map(({ node: doc }) => (
                <li key={doc.id}>
                  <Link to={`/docs/${doc.displayName}/`}>
                    {doc.displayName}
                  </Link>
                </li>
              ))}
            </ul>
          </DocsMenu>

          <DocsBody>{children}</DocsBody>
        </Grid>
      </>
    </ChakraProvider>
  );
};

export default DocsLayout;
