import React from 'react';
import DocsLayout from '../../components/ui/DocsLayout';

const index1 = () => (
  <DocsLayout>
    <>
      <h1>SpotCast website documentation</h1>

      <h2>Overview</h2>
      <p>
        The SpotCast website is designed to be lightening fast using a modern
        development stack which uses technology in use by the like of Netflix,
        Facebook and Expedia. The result for user is a lightening fast
        experience with modern interactions.
      </p>
      <p>
        For content managers the website is managed by a headless CMS called
        Dato CMS. This simplifies content management compared to something like
        Joomla or Wordpress and reduces content management is simply filling out
        a form. THe display of the site is a separate concern.
      </p>
      <p>
        From a developers point of view, the site uses modern development
        practices like repository management with git, continuous development
        and serverless stack.
      </p>
    </>
  </DocsLayout>
);

export default index1;
